import React from "react";
import {
  DashboardIcon,
  ApplicationIcon,
  ProductIcon,
  ClientIcon,
  BindingIcon,
  DeviceIcon,
  AuthHistoryIcon,
  EKYCIcon,
  MessagingIcon,
  NewMessageIcon,
  MessageHistoryIcon,
  TemplateIcon,
  ResourceIcon,
  ScheduleTaskIcon,
  TaskIcon,
  ExecutionHistoryIcon,
  AdminGearIcon,
  AdminIcon,
  AdminGroupIcon,
  SystemIcon,
  KeyIcon,
  ConfigIcon,
  InfoIcon,
} from "components/styled-icons";

const navConfigNested = [
  {
    name: "Home",
    children: [
      {
        name: "Dashboard",
        path: "",
        icon: <DashboardIcon />,
      },
    ],
  },
  {
    name: "Application",
    children: [
      {
        name: "Application",
        path: "/application",
        icon: <ApplicationIcon />,
      },
      {
        name: "Product",
        path: "/product",
        icon: <ProductIcon />,
      },
    ],
  },
  {
    name: "Client",
    children: [
      {
        name: "Client",
        path: "/client",
        icon: <ClientIcon />,
      },
      {
        name: "Device Binding",
        icon: <BindingIcon />,
        children: [
          {
            name: "Registered Device",
            path: "/binding/registered-device",
            icon: <DeviceIcon />,
          },
          {
            name: "Authentication History",
            path: "/binding/authentication-history",
            icon: <AuthHistoryIcon />,
          },
        ],
      },
      {
        name: "eKYC",
        path: "/ekyc",
        icon: <EKYCIcon />,
      },
      {
        name: "Messaging",
        icon: <MessagingIcon />,
        children: [
          {
            name: "Send New Message",
            path: "/messaging/new-message",
            icon: <NewMessageIcon />,
          },
          {
            name: "Messaging Template",
            path: "/messaging/messaging-template",
            icon: <TemplateIcon />,
          },
          {
            name: "Messaging History",
            path: "/messaging/messaging-history",
            icon: <MessageHistoryIcon />,
          },
        ],
      },
    ],
  },
  {
    name: "Management",
    children: [
      {
        name: "System Resource",
        path: "",
        icon: <ResourceIcon />,
      },
      {
        name: "Schedule Task",
        icon: <ScheduleTaskIcon />,
        children: [
          {
            name: "Task",
            path: "/task/task-job",
            icon: <TaskIcon />,
          },
          {
            name: "Execution History",
            path: "/task/execution-history",
            icon: <ExecutionHistoryIcon />,
          },
        ],
      },
      {
        name: "Administration",
        icon: <AdminIcon />,
        children: [
          {
            name: "Admin",
            path: "/management/admin",
            icon: <AdminGearIcon />,
          },
          {
            name: "Admin Group",
            path: "/management/admin-group",
            icon: <AdminGroupIcon />,
          },
        ],
      },
      {
        name: "System",
        icon: <SystemIcon />,
        children: [
          {
            name: "Encryption Key",
            path: "",
            icon: <KeyIcon />,
          },
          {
            name: "Service Configuration",
            path: "/system/service-configuration",
            icon: <ConfigIcon />,
          },
          {
            name: "Tenant Information",
            path: "/system/tenant-information",
            icon: <InfoIcon />,
          },
        ],
      },
    ],
  },
];

export default navConfigNested;
