import { createSlice, createSelector } from "@reduxjs/toolkit";

export const applicationSlice = createSlice({
  name: "application",
  initialState: {
    queryParams: {},
    editedDetails: {},
    ekycReqId: null,
    ekycHistoryQueryParams: {},
    documentImages: {},
    currentDocumentId: -1,
    currentAddressProofId: -1,
  },
  reducers: {
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    setEkycHistoryQueryParams: (state, action) => {
      state.ekycHistoryQueryParams = action.payload;
    },
    setEkycReqId: (state, action) => {
      state.ekycReqId = action.payload;
    },
    setCurrentDocumentId: (state, action) => {
      state.currentDocumentId = action.payload;
    },
    setCurrentAddressProofId: (state, action) => {
      state.currentAddressProofId = action.payload;
    },
    setDocumentImagesItem: (state, action) => {
      const { key, item } = action.payload;
      state.documentImages[key] = item;
    },
    resetDocumentImages: (state, action) => {
      state.documentImages = {};
    },
  },
});

const selectApplication = (state) => state.application;

export const selectApplicationState = createSelector(
  [selectApplication],
  (application) => {
    return {
      queryParams: application.queryParams,
      editedDetails: application.editedDetails,
      ekycReqId: application.ekycReqId,
      ekycHistoryQueryParams: application.ekycHistoryQueryParams,
      documentImages: application.documentImages,
      currentDocumentId: application.currentDocumentId,
      currentDocument: application.documentImages[application.currentDocumentId],
      currentAddressProofId: application.currentAddressProofId,
    };
  }
);

export const {
  setQueryParams,
  setEkycHistoryQueryParams,
  setEkycReqId,
  setDocumentImagesItem,
  resetDocumentImages,
  setCurrentDocumentId,
  setCurrentAddressProofId,
} = applicationSlice.actions;

export default applicationSlice.reducer;
