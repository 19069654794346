import { createSlice, createSelector } from "@reduxjs/toolkit";

export const bindingAuthHistorySlice = createSlice({
  name: "bindingAuthHistory",
  initialState: {
    queryParams: {},
    editedDetails: {},
  },
  reducers: {
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
  },
});

const selectBindingAuthHistory = (state) => state.bindingAuthHistory;

export const selectBindingAuthHistoryState = createSelector(
  [selectBindingAuthHistory],
  (bindingAuthHistory) => {
    return {
      queryParams: bindingAuthHistory.queryParams,
      editedDetails: bindingAuthHistory.editedDetails,
    };
  },
);

export const { setQueryParams } = bindingAuthHistorySlice.actions;

export default bindingAuthHistorySlice.reducer;
