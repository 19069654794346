import { createSlice, createSelector } from "@reduxjs/toolkit";

export const deviceRegistrationSlice = createSlice({
  name: "deviceRegistration",
  initialState: {
    queryParams: {},
    editedDetails: {},
  },
  reducers: {
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
  },
});

const selectDeviceRegistration = (state) => state.deviceRegistration;

export const selectDeviceRegistrationState = createSelector(
  [selectDeviceRegistration],
  (deviceRegistration) => {
    return {
      queryParams: deviceRegistration.queryParams,
      editedDetails: deviceRegistration.editedDetails,
    };
  },
);

export const { setQueryParams } = deviceRegistrationSlice.actions;

export default deviceRegistrationSlice.reducer;
