import { createSlice, createSelector } from "@reduxjs/toolkit";
import { updateNestedObjByPath } from "utils";

const newMessageInitialState = {
  inBatch: false,
  deliveryMethod: "sms",
  fromTemplate: false,
  destinations: {
    email: "",
    sms: "",
    pns: "",
  },
  content: {
    email: {
      title: "",
      content: "",
    },
    sms: {
      title: "",
      content: "",
    },
    pns: {
      title: "",
      content: "",
    },
  },
  template: {
    id: "",
    name: "",
    locale: "",
    keywordArguments: {},
  },
};
export const newMessageSlice = createSlice({
  name: "newMessage",
  initialState: newMessageInitialState,
  reducers: {
    resetState: (state, action) => {
      Object.entries(newMessageInitialState).forEach(([k, v]) => {
        state[k] = v;
      });
    },
    updateState: (state, action) => {
      // Update state by path
      let newState = action.payload;
      Object.entries(newState).forEach(([fieldpath, v]) => {
        updateNestedObjByPath(state, fieldpath, v);
      });
    },
  },
});

const selectNewMessage = (state) => state.newMessage;

export const selectNewMessageState = createSelector(
  [selectNewMessage],
  (newMessage) => {
    const deliveryMethod = newMessage.deliveryMethod;
    return {
      deliveryMethod,
      inBatch: newMessage.inBatch,
      fromTemplate: newMessage.fromTemplate,
      destination: newMessage.destinations[deliveryMethod],
      templateName: newMessage.template.name,
      templateId: newMessage.template.id,
      locale: newMessage.template.locale,
      title: newMessage.content[deliveryMethod].title,
      content: newMessage.content[deliveryMethod].content,
    };
  }
);
export const selectPostNewMessageState = createSelector(
  [selectNewMessage],
  (newMessage) => {
    const deliveryMethod = newMessage.deliveryMethod;
    const destination = {
      email: "email",
      sms: "phoneNumber",
      pns: "deviceId",
    }[deliveryMethod];

    return {
      recipient: { [destination]: newMessage.destinations[deliveryMethod] },
      recipients: newMessage.destinations[deliveryMethod]
        .split("\n")
        .map((d) => d.trim())
        .filter((d) => d.length)
        .map((d) => ({ [destination]: d })),
      messageContent: newMessage.content[deliveryMethod].content,
      templateName: newMessage.template.name,
      locale: newMessage.template.locale,
      subject: newMessage.content[deliveryMethod].title,
      messageTitle: newMessage.content[deliveryMethod].title,
      content: newMessage.content[deliveryMethod].content,
      keywordArguments: newMessage.template.keywordArguments,
      subjectKeywordArguments: newMessage.template.keywordArguments,
      titleKeywordArguments: newMessage.template.keywordArguments,
      contentKeywordArguments: newMessage.template.keywordArguments,
      contentType: "text/html",
    };
  }
);

export const { updateState, resetState } = newMessageSlice.actions;

export default newMessageSlice.reducer;
