import { createSlice, createSelector } from "@reduxjs/toolkit";

export const productSlice = createSlice({
  name: "product",
  initialState: {
    queryParams: {},
  },
  reducers: {
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
  },
});

const selectProduct = (state) => state.product;

export const selectProductState = createSelector(
  [selectProduct],
  (product) => {
    return {
      queryParams: product.queryParams,
    };
  }
);

export const {
  setQueryParams,
} = productSlice.actions;

export default productSlice.reducer;
