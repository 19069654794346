import { createSlice } from "@reduxjs/toolkit";
import { setAuth, clearAuth } from "./authSlice";

const historyRegisteredActions = [setAuth, clearAuth];

const historySlice = createSlice({
  name: "history",
  initialState: {
    lastAction: null,
    lastLoc: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    // Register action that could be shown in history/lastAction
    historyRegisteredActions.forEach((a) => {
      builder.addCase(a, (state, _) => {
        state.lastAction = a.type;
      });
    });
  },
});

// export const {
//   setLastLoc
// } = historySlice.actions;

export default historySlice.reducer;
