import { createSlice, createSelector } from "@reduxjs/toolkit";
import navConfigNested from "components/layouts/config-nav";

export const navSlice = createSlice({
  name: "nav",
  initialState: {
    openStates: navConfigNested.reduce((ret, group) => {
      group.children.forEach(lv1Itm => {
        if (lv1Itm.children && lv1Itm.children.length) {
          ret[lv1Itm.name] = false;
        }
      });
      return ret;
    }, {}),
  },
  reducers: {
    setOpenStates: (state, action) => {
      state.openStates = {
        ...state.openStates,
        ...action.payload,
      };
    },
  },
});

const selectNav = (state) => state.nav;

export const selectNavState = createSelector(
  [selectNav],
  (nav) => {
    return {
      openStates: nav.openStates,
    };
  },
);

export const { setOpenStates } = navSlice.actions;

export default navSlice.reducer;
