import React from "react";
import ThemeProvider from "theme";
import { Provider } from "react-redux";
import Store from "state/store";

const wrapper = ({ element }) => {
  return (
    <Provider store={Store}>
      <ThemeProvider>
        {element}
      </ThemeProvider>
    </Provider>
  );
};

export default wrapper;
