const buildTree = () => {
  let instance = {};
  instance._root = null;
  instance._nodeMap = {};
  instance.contains = (nodeId) => {
    return nodeId in instance._nodeMap;
  };
  instance.getNodeById = (nodeId) => {
    return instance._nodeMap[nodeId];
  };
  instance.addNew = (id, nContent) => {
    const node = buildNode(id);
    Object.keys(nContent).forEach((k) => {
      const property = nContent[k];
      node.setProperty(k, property);
    });
    instance._nodeMap[id] = node;
    return node;
  };
  instance.setRoot = (id, nContent) => {
    if (instance._root === null) {
      const root = instance.addNew(id, nContent);
      root.setParent(null);
      instance._root = root;
    } else {
      console.error("Root already exists, failed to setRoot");
    }
    return instance._root;
  };
  instance.getRoot = () => {
    return instance._root;
  };
  instance.isRoot = (node) => {
    return node.id === instance._root.id;
  };
  instance.isLeaf = (node) => {
    return node.getChildren().length === 0;
  };
  instance.addChildTo = (nParent, id, nContent) => {
    // Find Node from nodeMap
    let childNode;
    if (id in instance._nodeMap) {
      childNode = instance._nodeMap[id];
      Object.keys(nContent).forEach((k) => {
        const property = nContent[k];
        childNode.setProperty(k, property);
      });
    } else {
      childNode = instance.addNew(id, nContent);
    }
    nParent.addChild(childNode);
    return childNode;
  };
  instance.getDepth = (node) => {
    let depth;
    if (instance.isRoot(node)) {
      depth = 0;
    } else {
      depth = 1 + instance.getDepth(node.getParent());
    }
    return depth;
  };
  instance.getAncestors = (node) => {
    const ret = [];
    let n = node;
    while (!instance.isRoot(n)) {
      let p = n.getParent();
      ret.push(p);
      n = p;
    }
    return ret;
  };
  instance.deleteLeaf = (node) => {
    if (!instance.isLeaf(node)) {
      return;
    }
    // Remove from parent
    const nParent = node.getParent();
    nParent.deleteChild(node.id);

    // Remove from nodeMap
    delete instance._nodeMap[node.id];
  };
  instance.preOrder = (node) => {
    const startNode = node ? node : instance._root;
    const ret = [];
    instance._preOrder(startNode, ret);
    return ret;
  };
  instance._preOrder = (node, ret) => {
    ret.push(node);
    const childs = node.getChildren();
    childs.forEach((child) => {
      instance._preOrder(child, ret);
    });
  };
  return instance;
};

const buildNode = (nodeId) => {
  let instance = {};
  instance.id = nodeId;
  instance._nParent = null;
  instance._nChildren = [];
  instance._properties = {};

  instance.setParent = (parentNode) => {
    instance._nParent = parentNode;
    return instance;
  };
  instance.getParent = () => {
    return instance._nParent;
  };
  instance.addChild = (childNode) => {
    instance._nChildren.push(childNode);
    childNode.setParent(instance);
    return instance;
  };
  instance.deleteChild = (childId) => {
    instance._nChildren = instance._nChildren.filter((c) => c.id !== childId);
    return;
  };
  instance.getChildren = () => {
    return instance._nChildren;
  };
  instance.setProperty = (propertyName, property) => {
    instance._properties[propertyName] = property;
    return instance;
  };
  instance.getProperty = (propertyName) => {
    // Return deep copy
    return propertyName in instance._properties
      ? JSON.parse(JSON.stringify(instance._properties[propertyName]))
      : null;
  };
  return instance;
};

export default buildTree;
