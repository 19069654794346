import buildTree from "utils/tree";

export const parseJwt = (token) => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const saveState = (groupName, state) => {
  if (typeof window === "undefined") {
    return;
  }
  Object.entries(state).forEach(([k, v]) => {
    sessionStorage.setItem(`value/${groupName}/${k}`, v);
    sessionStorage.setItem(`type/${groupName}/${k}`, typeof v);
  });
};

export const loadState = (groupName, keys) => {
  let state = {};
  if (typeof window === "undefined") {
    return state;
  }
  keys.forEach((k) => {
    let storedValue = sessionStorage.getItem(`value/${groupName}/${k}`);
    let storedType = sessionStorage.getItem(`type/${groupName}/${k}`); 
    if (storedValue && storedType) {
      if (storedType === "boolean") {
        if (storedValue === "true") {
          state[k] = true
        } else if (storedValue === "false") {
          state[k] = false
        } else {
          state[k] = undefined
        }
      } else {
        state[k] = storedValue;
      }
    }
  });
  return state;
};

export const removeState = (groupName, keys) => {
  if (typeof window === "undefined") {
    return;
  }
  keys.forEach((k) => {
    try {
      sessionStorage.removeItem(`value/${groupName}/${k}`);
    } catch (e) {
      console.error(`Failed to remove state value/${groupName}/${k}: ${e}.`);
    }
    try {
      sessionStorage.removeItem(`type/${groupName}/${k}`);
    } catch (e) {
      console.error(`Failed to remove state type/${groupName}/${k}: ${e}.`);
    }
  });
};

export const assignObject = (oldValues, newValues) => {
  // assign new values to existing values and return new object
  // undefined value could remove existing value
  let ret = Object.entries({ ...oldValues, ...newValues }).filter(
    ([k, v]) => v !== undefined
  );
  return Object.fromEntries(ret);
};

export const isScalarArrayEqual = (array1, array2) => {
  // https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript
  const array2Sorted = array2.slice().sort();
  return (
    array1.length === array2.length &&
    array1
      .slice()
      .sort()
      .every(function (value, index) {
        return value === array2Sorted[index];
      })
  );
};

export const makeRoleTree = (d) => {
  const tree = buildTree();
  const rootId = -999;
  tree.setRoot(rootId, {
    code: "Admin Roles",
    isRole: false,
  });
  if (d.length > 0) {
    d.forEach((d) => {
      let parent;
      const pid = d.parentRoleId === undefined ? rootId : d.parentRoleId;
      if (tree.contains(pid)) {
        parent = tree.getNodeById(pid);
      } else {
        parent = tree.addNew(pid, {});
      }
      tree.addChildTo(parent, d.id, {
        code: d.code,
        order: d.order,
        requiredId: d.requiredRoleId ? d.requiredRoleId : null,
      });
    });
  }
  // Set state index
  tree.preOrder().forEach((n, idx) => {
    n.setProperty("stateIndex", idx);
  });
  return tree;
};

export const isEncryptedContent = (content) => {
  if (!content) {
    return false;
  }
  return content.match(/<encrypted>(.*?)<\/encrypted>/g);
};

export const arrayIsEqual = (arr1, arr2) => {
  if (!(arr1 instanceof Array && arr2 instanceof Array)) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
      if (!arrayIsEqual(arr1[i], arr2[i])) {
        return false;
      }
    } else if (arr1[i] instanceof Object && arr2[i] instanceof Object) {
      if (!jsonObjectIsEqual(arr1[i], arr2[i])) {
        return false;
      }
    } else if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

export const jsonObjectIsEqual = (obj1, obj2) => {
  if (obj1 instanceof Array && obj2 instanceof Array) {
    return arrayIsEqual(obj1, obj2);
  } else if (!(obj1 instanceof Object && obj2 instanceof Object)) {
    return obj1 === obj2;
  }

  // Compare Objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (!jsonObjectIsEqual(keys1, keys2)) {
    return false;
  }

  for (const propName of keys2) {
    if (!jsonObjectIsEqual(obj1[propName], obj2[propName])) {
      return false;
    }
  }
  return true;
};

export const getObjectUpdates = (origConf, newConf) => {
  let updates = Object.entries(newConf).reduce((sm, [k, v]) => {
    let oldValue = origConf[k];
    // const isModified = !jsonObjectIsEqual(oldValue, v);
    let isModified;
    if (
      typeof v === "object" &&
      typeof oldValue === "object" &&
      oldValue !== null &&
      oldValue !== undefined
    ) {
      // If new value is object and old value exist
      isModified = !jsonObjectIsEqual(oldValue, v);
    } else {
      isModified = oldValue !== v;
    }
    if (isModified) {
      if (v === "" || v === null) {
        sm[k] = null;
      } else if (v instanceof Array && !v.length) {
        sm[k] = null;
      } else {
        sm[k] = v;
      }
    }
    return sm;
  }, {});
  return updates;
};

export const checkIsUndefined = (val) => typeof val === "undefined";

export const updateNestedObjByPath = (nestedObj, fieldpath, newValue) => {
  // Update nestedObject in place, with path syntax a.b.c.d
  const paths = fieldpath.split(".");
  let iobj = newValue;
  if (paths.length > 1) {
    paths
      .slice()
      .reverse()
      .forEach((ipath, i) => {
        let jobj = nestedObj;
        if (i < paths.length - 1) {
          paths.forEach((jpath, j) => {
            if (j < paths.length - 1 - i) {
              jobj = jobj[jpath];
              if (typeof jobj === 'undefined') {
                console.error(`${jpath} not found when updating ${fieldpath} on:`, jobj);
              }
            }
          })
          jobj[ipath] = iobj;
          iobj = jobj;
        }
      });
  } else {
    nestedObj[paths[0]] = newValue;
  }
};

export const getWindowDimensions = () =>  {
  if (typeof window === "undefined") {
    return;
  }
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const getMimeTypeFromFileExtension = (fext) => {
  const types  = {
    "html": "text/html",
    "css": "text/css",
    "xml": "text/xml",
    "gif": "image/gif",
    "jpg": "image/jpeg",
    "js": "application/x-javascript",
    "atom": "application/atom+xml",
    "rss": "application/rss+xml",
    "mml": "text/mathml",
    "txt": "text/plain",
    "jad": "text/vnd.sun.j2me.app-descriptor",
    "wml": "text/vnd.wap.wml",
    "htc": "text/x-component",
    "png": "image/png",
    "tiff": "image/tiff",
    "wbmp": "image/vnd.wap.wbmp",
    "ico": "image/x-icon",
    "jng": "image/x-jng",
    "bmp": "image/x-ms-bmp",
    "svg": "image/svg+xml",
    "webp": "image/webp",
    "jar": "application/java-archive",
    "hqx": "application/mac-binhex40",
    "doc": "application/msword",
    "pdf": "application/pdf",
    "ps": "application/postscript",
    "rtf": "application/rtf",
    "xls": "application/vnd.ms-excel",
    "ppt": "application/vnd.ms-powerpoint",
    "wmlc": "application/vnd.wap.wmlc",
    "kml": "application/vnd.google-earth.kml+xml",
    "kmz": "application/vnd.google-earth.kmz",
    "7z": "application/x-7z-compressed",
    "cco": "application/x-cocoa",
    "jardiff": "application/x-java-archive-diff",
    "jnlp": "application/x-java-jnlp-file",
    "run": "application/x-makeself",
    "pl": "application/x-perl",
    "pdb": "application/x-pilot",
    "rar": "application/x-rar-compressed",
    "rpm": "application/x-redhat-package-manager",
    "sea": "application/x-sea",
    "swf": "application/x-shockwave-flash",
    "sit": "application/x-stuffit",
    "tcl": "application/x-tcl",
    "pem": "application/x-x509-ca-cert",
    "xpi": "application/x-xpinstall",
    "xhtml": "application/xhtml+xml",
    "zip": "application/zip",
    "mid": "audio/midi",
    "mp3": "audio/mpeg",
    "ogg": "audio/ogg",
    "ra": "audio/x-realaudio",
    "3gp": "video/3gpp",
    "mpg": "video/mpeg",
    "mov": "video/quicktime",
    "flv": "video/x-flv",
    "mng": "video/x-mng",
    "asf": "video/x-ms-asf",
    "wmv": "video/x-ms-wmv",
    "avi": "video/x-msvideo",
    "mp4": "video/mp4",
  }
  if (typeof fext === 'string' || fext instanceof String) {
    const fileExtension = fext.toLowerCase();
    return types[fileExtension] ? types[fileExtension] : "";
  } else {
    return "";
  }
};

export const getHandleOnKeyUp = (ref) => (evt) => {
  const { keyCode } = evt;
  if (keyCode === 13) {
    if (ref && ref.current) {
      let inputs = Array.from(ref.current.querySelectorAll("input")).filter(elem => !elem.disabled);
      let currentInputIdx = inputs.findIndex(elem => elem === evt.target);
      if (inputs.length && currentInputIdx < inputs.length) {
        if (currentInputIdx > -1) {
          if (!inputs[currentInputIdx].value) {
            return;
          }
        }
        if (currentInputIdx < inputs.length - 1) {
          inputs[currentInputIdx+1].focus(); 
        }
      }
    }
  }
}