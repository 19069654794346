import { createSlice, createSelector } from "@reduxjs/toolkit";

export const taskJobSlice = createSlice({
  name: "taskJob",
  initialState: {
    taskName: "",
    queryParams: {},
    jobQueryParams: {},
    inputSchema: {},
    jobId: null,  
    jobExecutionHistoryQueryParams: {},
  },
  reducers: {
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    setTaskName: (state, action) => {
      state.taskName = action.payload;
    },
    setJobQueryParams: (state, action) => {
      state.jobQueryParams = action.payload;
    },
    setTask: (state, action) => {
      state.taskName = action.payload.taskName ?? "";
      state.inputSchema = action.payload.inputSchema ?? {};
    },
    setJobId: (state, action) => {
      state.jobId = action.payload;
    },
    setJobExecutionHistoryQueryParams: (state, action) => {
      state.jobExecutionHistoryQueryParams = action.payload;
    },
  },
});

const selectTaskJob = (state) => state.taskJob;

export const selectTaskJobState = createSelector(
  [selectTaskJob],
  (taskJob) => {
    return {
      queryParams: taskJob.queryParams,
      jobParams: {
        ...taskJob.jobQueryParams,
        taskName: taskJob.taskName,
      },
      jobQueryParams: taskJob.jobQueryParams,
      jobExecutionHistoryQueryParams: taskJob.jobExecutionHistoryQueryParams,
      jobId: taskJob.jobId,
      taskName: taskJob.taskName,
      inputSchema: taskJob.inputSchema,
    };
  },
);

export const { setQueryParams, setJobQueryParams, setTask, setJobId, setJobExecutionHistoryQueryParams } =
  taskJobSlice.actions;

export default taskJobSlice.reducer;
