import { createSlice, createSelector } from "@reduxjs/toolkit";
import moment from "moment";
import { parseJwt, loadState } from "utils";

export const defaultState = {
  tenantId: null,
  adminId: null,
  username: null,
  atok: null,
  exp: null,
  scope: [],
  adminName: "",
  emailAddress: "",
  passwordExpired: false,
  t: 0,
};
const storedState = loadState("auth", Object.keys(defaultState));
const IsExpiringTime = 300;

export const authSlice = createSlice({
  name: "auth",
  initialState: Object.keys(storedState).length ? storedState : defaultState,
  reducers: {
    setAuth: (state, action) => {
      state.atok = action.payload.accessToken;
      const content = parseJwt(state.atok);
      state.exp = parseInt(content.exp);
      state.scope = content.scope ? content.scope.split(" ") : [];
      state.tenantId = content.tid;
      state.adminId = content.uid;
      state.username = content.uname;
      // state.passwordExpired = !!action.payload.passwordExpired;
    },
    clearAuth: (state, action) => {
      state.tenantId = null;
      state.adminId = null;
      state.username = null;
      state.atok = null;
      state.exp = null;
      state.scope = [];
      state.adminName = "";
      state.emailAddress = "";
    },
    setUserDetail: (state, action) => {
      state.emailAddress = action.payload.emailAddress ?? "";
      state.adminName = action.payload.adminName ?? "";
      // if (action.payload.changePasswordRequired !== undefined) {
      //   state.passwordExpired = action.payload.changePasswordRequired;
      // }
    },
    setT: (state, action) => {
      state.t = moment().unix();
    },
  },
});

const selectAuth = (state) => state.auth;

export const selectAuthState = createSelector([selectAuth], (auth) => {
  const isATokNotExpired = auth.exp !== null ? auth.exp - auth.t > 0 : false;
  // const isAtokExpiring =
  //   auth.exp !== null ? auth.exp - auth.t < IsExpiringTime : false;
  return {
    tenantId: auth.tenantId,
    adminId: auth.adminId,
    username: auth.username,
    atok: auth.atok,
    scope: auth.scope,
    adminName: auth.adminName,
    emailAddress: auth.emailAddress,
    isAuthenticated: !!auth.atok && isATokNotExpired,
    refreshRequired: !!auth.atok && !isATokNotExpired,
    // passwordExpired: auth.passwordExpired,
    // t: auth.t,
  };
});

export const { setAuth, clearAuth, setUserDetail, setT } = authSlice.actions;

export default authSlice.reducer;
