import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import styled from "styled-components";

import {
  BsArrowClockwise,
  BsList,
  BsPersonCircle,
  BsHouseFill,
  BsPieChart,
  BsAppIndicator,
  BsListCheck,
  BsCalendarCheck,
  BsCart,
  BsCartPlus,
  BsPersonGear,
  BsPersonWorkspace,
  BsCommand,
  BsPeople,
  BsGear,
  BsPersonVcard,
  BsPersonPlus,
  BsCast,
  BsLink,
  BsKey,
  BsBoundingBoxCircles,
  BsChevronBarRight,
  BsChevronBarLeft,
  BsChevronLeft,
  BsChevronRight,
  BsChevronDown,
  BsChevronUp,
  BsXLg,
  BsArrowUp,
  BsArrowDown,
  BsFilePerson,
  BsFilter,
  BsPlusCircle,
  BsDashCircle,
  BsXCircleFill,
  BsCheckCircleFill,
  BsExclamationOctagon,
  BsXCircle,
  BsCheckCircle,
  BsQuestionCircle,
  BsPauseCircle,
  BsArrowReturnLeft,
  BsUpload,
  BsTextareaT,
  BsPlus,
  BsArrowsFullscreen,
  BsFullscreen,
} from "react-icons/bs";

import {
  TbMessage,
  TbMessage2Plus,
  TbTemplate,
  TbClockShield,
  TbMessage2Search,
  TbTerminal2,
  TbCircleKey,
  TbClockEdit,
  TbClockSearch,
  TbAddressBook,
  TbClockExclamation,
  TbInfinity,
  TbCircleCheck,
} from "react-icons/tb";

import {
  MdMoreVert,
  MdAddCircleOutline,
  MdOutlineGroupAdd,
  MdOutlineSaveAs,
  MdSend,
  MdInfoOutline,
  MdPlayCircleOutline,
  MdPauseCircleOutline,
  MdFormatListBulletedAdd,
  MdOutlineCompareArrows,
  MdOutlineComment,
  MdOutlineReviews,
  MdOutlineEmail,
  MdOutlinePhone,
  MdOpenInNew,
} from "react-icons/md";

import {
  FiEdit,
  FiInfo,
  FiUnlock,
  FiTrash,
  FiMinimize2,
  FiLock,
} from "react-icons/fi";

import { IoRefreshCircleOutline } from "react-icons/io5";
import { GrPowerReset } from "react-icons/gr";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { CgSandClock } from "react-icons/cg"

// Layout icon
export const HomeIcon = styled(BsHouseFill)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const UserIcon = styled(BsPersonCircle)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const MenuIcon = styled(BsList)`
  height: 1.5rem;
  width: 1.5rem;
  stroke-width: 1;
`;

// Nav icon
export const DashboardIcon = styled(BsPieChart)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const ApplicationIcon = styled(BsAppIndicator)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const ProductIcon = styled(BsCart)`
  height: 1.5rem;
  width: 1.5rem;
  stroke-width: 0.2;
`;

export const ClientIcon = styled(BsFilePerson)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const BindingIcon = styled(BsLink)`
  height: 1.5rem;
  width: 1.5rem;
  stroke-width: 0.2;
`;

export const DeviceIcon = styled(BsCast)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const AuthHistoryIcon = styled(TbClockShield)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const EKYCIcon = styled(BsPersonVcard)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const MessagingIcon = styled(TbMessage)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const NewMessageIcon = styled(TbMessage2Plus)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const MessageHistoryIcon = styled(TbMessage2Search)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const TemplateIcon = styled(TbTemplate)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const ResourceIcon = styled(BsBoundingBoxCircles)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const ScheduleTaskIcon = styled(BsCalendarCheck)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const TaskIcon = styled(BsListCheck)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const ExecutionHistoryIcon = styled(TbTerminal2)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const AdminGearIcon = styled(BsPersonGear)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const AdminIcon = styled(BsPersonWorkspace)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const AdminGroupIcon = styled(BsPeople)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const SystemIcon = styled(BsCommand)`
  height: 1.5rem;
  width: 1.5rem;
  stroke-width: 0.2;
`;

export const KeyIcon = styled(BsKey)`
  height: 1.5rem;
  width: 1.5rem;
  stroke-width: 0.2;
`;

export const ConfigIcon = styled(BsGear)`
  height: 1.5rem;
  width: 1.5rem;
  stroke-width: 0.2;
`;

export const InfoIcon = styled(MdInfoOutline)`
  height: 1.5rem;
  width: 1.5rem;
`;
// Component icon

export const LoadingIcon = styled(AiOutlineLoading3Quarters)`
  height: 1.2rem;
  width: 1.2rem;
  animation: spin 1s linear infinite;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const NextIcon = styled(BsChevronRight)`
  height: 1rem;
  width: 1rem;
`;

export const PrevIcon = styled(BsChevronLeft)`
  height: 1rem;
  width: 1rem;
`;

export const FirstItemIcon = styled(BsChevronBarLeft)`
  height: 1rem;
  width: 1rem;
`;

export const LastItemIcon = styled(BsChevronBarRight)`
  height: 1rem;
  width: 1rem;
`;

export const SortAscIcon = styled(BsArrowUp)`
  height: 0.8rem;
  width: 1rem;
`;

export const SortDescIcon = styled(BsArrowDown)`
  height: 0.8rem;
  width: 1rem;
`;

export const TableCheckIcon = styled(BsCheckCircleFill)`
  height: 1.2rem;
  width: 1.2rem;
  margin-top: 0.3rem;
  color: #4584c5;
  border-radius: 0.625rem;
  background-color: #fff;
`;

export const TableCrossIcon = styled(BsXCircleFill)`
  height: 1.2rem;
  width: 1.2rem;
  margin-top: 0.3rem;
  color: #919eab;
  border-radius: 0.625rem;
  background-color: #fff;
`;

export const SwitchCheckIcon = styled(BsCheckCircleFill)`
  height: 1.25rem;
  width: 1.25rem;
  color: #4584c5;
  border-radius: 0.625rem;
  background-color: #fff;
`;

export const SwitchCrossIcon = styled(BsXCircleFill)`
  height: 1.25rem;
  width: 1.25rem;
  color: #919eab;
  border-radius: 0.625rem;
  background-color: #fff;
`;

export const TableDetailIcon = styled(MdMoreVert)`
  height: 1.25rem;
  width: 1.25rem;
`;

export const FilterIcon = styled(BsFilter)`
  height: 1.5rem;
  width: 1.5rem;
  stroke-width: 0.5;
`;

export const AddItemIcon = styled(MdAddCircleOutline)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const CloseIcon = styled(BsXLg)`
  height: 1.25rem;
  width: 1.25rem;
  stroke-width: 1;
`;

export const MinimizeIcon = styled(FiMinimize2)`
  height: 1.25rem;
  width: 1.25rem;
  stroke-width: 2.5;
`;

export const DeleteIcon = styled(FiTrash)`
  height: 1.2rem;
  width: 1.2rem;
  color: #ff5630;
`;

export const DetailIcon = styled(FiInfo)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const EditIcon = styled(FiEdit)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const UnlockIcon = styled(FiUnlock)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const LockIcon = styled(FiLock)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const EnableIcon = styled(BsCheckCircleFill)`
  height: 1.2rem;
  width: 1.2rem;
  color: #4584c5;
  border-radius: 0.625rem;
  background-color: #fff;
`;

export const DisableIcon = styled(BsXCircleFill)`
  height: 1.2rem;
  width: 1.2rem;
  color: #919eab;
  border-radius: 0.625rem;
  background-color: #fff;
`;

export const ExpirePasswordIcon = styled(BsExclamationOctagon)`
  height: 1.2rem;
  width: 1.2rem;
  stroke-width: 0.4;
`;

export const AddAdminIcon = styled(BsPersonPlus)`
  height: 1.4rem;
  width: 1.4rem;
  stroke-width: 0.4;
`;

export const AddAdminGroupIcon = styled(MdOutlineGroupAdd)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const EkycCheckIcon = styled(BsCheckCircle)`
  height: 1.2rem;
  width: 1.2rem;
  color: #00a76f;
  border-radius: 0.625rem;
`;

export const EkycCrossIcon = styled(BsXCircle)`
  height: 1.2rem;
  width: 1.2rem;
  color: #ff5630;
  border-radius: 0.625rem;
`;

export const EkycUnknownIcon = styled(BsQuestionCircle)`
  height: 1.2rem;
  width: 1.2rem;
  color: #919eab;
  border-radius: 0.625rem;
`;

export const EkycPendingIcon = styled(BsPauseCircle)`
  height: 1.2rem;
  width: 1.2rem;
  color: #ffab00;
  border-radius: 0.625rem;
`;

export const ReturnIcon = styled(BsArrowReturnLeft)`
  height: 1.2rem;
  width: 1.2rem;
  stroke-width: 0.4;
`;

export const RefreshIcon = styled(BsArrowClockwise)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const ExpandMoreIcon = styled(BsChevronDown)`
  height: 1rem;
  width: 1rem;
  stroke-width: 1;
`;
export const CollapseIcon = styled(BsChevronUp)`
  height: 1rem;
  width: 1rem;
  stroke-width: 1;
`;
export const FileUploadIcon = styled(BsUpload)`
  height: 1.2rem;
  width: 1.2rem;
`;
export const TextInputIcon = styled(BsTextareaT)`
  height: 1.2rem;
  width: 1.2rem;
`;
export const RefreshKeyIcon = styled(IoRefreshCircleOutline)`
  height: 1.5rem;
  width: 1.5rem;
`;
export const AccessKeyIcon = styled(TbCircleKey)`
  height: 1.5rem;
  width: 1.5rem;
  stroke-width: 1.5;
`;
export const HistoricPasswordCountIcon = styled(TbClockEdit)`
  height: 1.3rem;
  width: 1.3rem;
  stroke-width: 1.5;
`;
export const PlusIcon = styled(BsPlusCircle)`
  height: 1rem;
  width: 1rem;
`;
export const MinusIcon = styled(BsDashCircle)`
  height: 1rem;
  width: 1rem;
`;
export const InnerAddIcon = styled(BsPlus)`
  height: 1.2rem;
  width: 1.2rem;
`;
export const SaveEditedIcon = styled(MdOutlineSaveAs)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const AddTemplateIcon = styled(() => (
  <SvgIcon>
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      height="200px"
      width="200px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 4 m0 1a1 1 0 0 1 1 -1 h11 a1 1 0 0 1 1 1 v1.5 a1 1 0 0 1 -1 1 h-11 a1 1 0 0 1 -1 -1z"></path>
      <path d="M4 11 m0 1a1 1 0 0 1 1 -1h3 a1 1 0 0 1 1 1v5a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1z"></path>
      <path d="M12 11l5 0"></path>
      <path d="M12 14.5l3 0"></path>
      <path d="M12 18l0.5 0"></path>
      <path d="M15.5 18h5"></path>
      <path d="M18 15.5 v5"></path>
    </svg>
  </SvgIcon>
))`
  height: 1.2rem;
  width: 1.2rem;
`;

export const ResetIcon = styled(GrPowerReset)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const SendIcon = styled(MdSend)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const PlayIcon = styled(MdPlayCircleOutline)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const PauseIcon = styled(MdPauseCircleOutline)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const AddTaskIcon = styled(MdFormatListBulletedAdd)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const ApplicationHistoryIcon = styled(TbClockSearch)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const ApplicationCompareIcon = styled(MdOutlineCompareArrows)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const EKYCHistoryIcon = styled(() => (
  <SvgIcon>
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 256 256"
      height="200px"
      width="200px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M181.36,114.73l-48,24A12,12,0,0,1,116,128V72a12,12,0,0,1,24,0v36.58l30.63-15.31a12,12,0,1,1,10.73,21.46Zm45.72,120.86a12,12,0,0,1-14.68-8.51C210,218.2,201.65,212,192,212s-18,6.2-20.4,15.08A12,12,0,0,1,160,236a11.77,11.77,0,0,1-3.09-.41,12,12,0,0,1-8.52-14.68,43.76,43.76,0,0,1,15.08-23,36,36,0,1,1,57,0,43.7,43.7,0,0,1,15.08,23A12,12,0,0,1,227.08,235.59ZM180,176a12,12,0,1,0,12-12A12,12,0,0,0,180,176Zm-62.52,35.35a84,84,0,1,1,93.87-93.87,12,12,0,1,0,23.81-3A108,108,0,1,0,114.51,235.16a11,11,0,0,0,1.5.1,12,12,0,0,0,1.47-23.91Z"></path>
    </svg>
  </SvgIcon>
))`
  height: 1.4rem;
  width: 1.4rem;
`;

export const EKYCDetailIcon = styled(BsPersonVcard)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const AddrBookIcon = styled(TbAddressBook)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const CommentIcon = styled(MdOutlineComment)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const ReviewIcon = styled(MdOutlineReviews)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const PhoneIcon = styled(MdOutlinePhone)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const EmailIcon = styled(MdOutlineEmail)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const VerifiedIcon = styled(BsCheckCircle)`
  height: 1rem;
  width: 1rem;
  stroke-width: 1;
  border-radius: 0.625rem;
  color: #00a76f;
`;

export const BrokenImageIcon = styled(() => (
  <SvgIcon>
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 256 256"
      height="200px"
      width="200px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M216,42H40A14,14,0,0,0,26,56V200a14,14,0,0,0,14,14h64a6,6,0,0,0,5.69-4.1l15.12-45.36,37.42-15a6,6,0,0,0,3.34-3.34l15-37.42L225.9,93.69A6,6,0,0,0,230,88V56A14,14,0,0,0,216,42ZM117.77,154.43a6,6,0,0,0-3.46,3.67L99.68,202H40a2,2,0,0,1-2-2V171.17l52.58-52.58a2,2,0,0,1,2.83,0L126,151.15ZM218,83.68,174.1,98.31a6,6,0,0,0-3.67,3.46l-15.05,37.61L138.1,146.3l-36.2-36.2a14,14,0,0,0-19.8,0L38,154.2V56a2,2,0,0,1,2-2H216a2,2,0,0,1,2,2Zm9.51,33.18a6,6,0,0,0-5.41-.82L198.3,124a6,6,0,0,0-3.67,3.47L180,164l-36.56,14.63A6,6,0,0,0,140,182.3L132,206.1a6,6,0,0,0,5.69,7.9H216a14,14,0,0,0,14-14V121.73A6,6,0,0,0,227.51,116.86ZM218,200a2,2,0,0,1-2,2H146.06l4.42-13.26,36.37-14.55a6,6,0,0,0,3.34-3.34l14.55-36.37L218,130.06Z"></path>
    </svg>
  </SvgIcon>
))`
  height: 1rem;
  width: 1rem;
`;

export const IsEffectiveIcon = styled(TbCircleCheck)`
  height: 1.2rem;
  width: 1.2rem;
  color: #00a76f;
`;

export const EffectiveAfterIcon = styled(CgSandClock)`
  height: 1.2rem;
  width: 1.2rem;
  color: #FFAB00;
`;

export const ExpiredIcon = styled(TbClockExclamation)`
  height: 1.2rem;
  width: 1.2rem;
  color: #FF5630;
`;

export const AddProductIcon = styled(BsCartPlus)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const FullScreenIcon = styled(BsArrowsFullscreen)`
  height: 1rem;
  width: 1rem;
`;

export const OpenInNewIcon = styled(MdOpenInNew)`
  height: 1.2rem;
  width: 1.2rem;
`;

export const InfinityIcon = styled(TbInfinity)`
  height: 1.2rem;
  width: 1.2rem;
  color: #00a76f;
`;
