exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-application-[id]-js": () => import("./../../../src/pages/application/[id].js" /* webpackChunkName: "component---src-pages-application-[id]-js" */),
  "component---src-pages-application-index-js": () => import("./../../../src/pages/application/index.js" /* webpackChunkName: "component---src-pages-application-index-js" */),
  "component---src-pages-binding-authentication-history-js": () => import("./../../../src/pages/binding/authentication-history.js" /* webpackChunkName: "component---src-pages-binding-authentication-history-js" */),
  "component---src-pages-binding-registered-device-js": () => import("./../../../src/pages/binding/registered-device.js" /* webpackChunkName: "component---src-pages-binding-registered-device-js" */),
  "component---src-pages-change-password-js": () => import("./../../../src/pages/change-password.js" /* webpackChunkName: "component---src-pages-change-password-js" */),
  "component---src-pages-client-index-js": () => import("./../../../src/pages/client/index.js" /* webpackChunkName: "component---src-pages-client-index-js" */),
  "component---src-pages-ekyc-[id]-js": () => import("./../../../src/pages/ekyc/[id].js" /* webpackChunkName: "component---src-pages-ekyc-[id]-js" */),
  "component---src-pages-ekyc-index-js": () => import("./../../../src/pages/ekyc/index.js" /* webpackChunkName: "component---src-pages-ekyc-index-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-management-admin-group-js": () => import("./../../../src/pages/management/admin-group.js" /* webpackChunkName: "component---src-pages-management-admin-group-js" */),
  "component---src-pages-management-admin-js": () => import("./../../../src/pages/management/admin.js" /* webpackChunkName: "component---src-pages-management-admin-js" */),
  "component---src-pages-messaging-messaging-history-js": () => import("./../../../src/pages/messaging/messaging-history.js" /* webpackChunkName: "component---src-pages-messaging-messaging-history-js" */),
  "component---src-pages-messaging-messaging-template-js": () => import("./../../../src/pages/messaging/messaging-template.js" /* webpackChunkName: "component---src-pages-messaging-messaging-template-js" */),
  "component---src-pages-messaging-new-message-js": () => import("./../../../src/pages/messaging/new-message.js" /* webpackChunkName: "component---src-pages-messaging-new-message-js" */),
  "component---src-pages-product-[code]-js": () => import("./../../../src/pages/product/[code].js" /* webpackChunkName: "component---src-pages-product-[code]-js" */),
  "component---src-pages-product-index-js": () => import("./../../../src/pages/product/index.js" /* webpackChunkName: "component---src-pages-product-index-js" */),
  "component---src-pages-system-service-configuration-js": () => import("./../../../src/pages/system/service-configuration.js" /* webpackChunkName: "component---src-pages-system-service-configuration-js" */),
  "component---src-pages-system-tenant-information-js": () => import("./../../../src/pages/system/tenant-information.js" /* webpackChunkName: "component---src-pages-system-tenant-information-js" */),
  "component---src-pages-task-execution-history-js": () => import("./../../../src/pages/task/execution-history.js" /* webpackChunkName: "component---src-pages-task-execution-history-js" */),
  "component---src-pages-task-task-job-js": () => import("./../../../src/pages/task/task-job.js" /* webpackChunkName: "component---src-pages-task-task-job-js" */)
}

