import { createSlice, createSelector } from "@reduxjs/toolkit";

export const clientSlice = createSlice({
  name: "client",
  initialState: {
    queryParams: {},
    editedDetails: {},
  },
  reducers: {
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    saveEditedDetail: (state, action) => {
      let detailId = action.payload.detailId;
      let stateMap = action.payload.stateMap;
      let detailData = action.payload.detailData;
      state.editedDetails[String(detailId)] = {
        stateMap,
        detailData,
      };
    },
    removeEditedDetail: (state, action) => {
      let detailId = action.payload;
      let { [detailId]: _,  ...rest } = state.editedDetails;
      state.editedDetails = rest;
    }
  },
});

const selectClient = (state) => state.client;

export const selectClientState = createSelector(
  [selectClient],
  (client) => {
    return {
      queryParams: client.queryParams,
      editedDetails: client.editedDetails,
    };
  },
);

export const { setQueryParams, saveEditedDetail, removeEditedDetail } = clientSlice.actions;

export default clientSlice.reducer;
