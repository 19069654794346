import { createSlice, createSelector } from "@reduxjs/toolkit";

export const executionHistorySlice = createSlice({
  name: "executionHistory",
  initialState: {
    queryParams: {},
  },
  reducers: {
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
  },
});

const selectExecutionHistory = (state) => state.executionHistory;

export const selectExecutionHistoryState = createSelector(
  [selectExecutionHistory],
  (executionHistory) => {
    return {
      queryParams: executionHistory.queryParams,
    };
  },
);

export const { setQueryParams } =
  executionHistorySlice.actions;

export default executionHistorySlice.reducer;
